$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offsetDefault = 150; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight(),
                offset = $(this).data("animation-offset") ?? offsetDefault;

            if ((elementTop + offset + 40) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    var delay = $(".js-header").data("animation-delay");
    $(".js-header").css("transition-delay", delay + "s").addClass("animateIn--active");

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * stageVideo
    // *
    // *
    setTimeout(function() {
            $(".js-stage-video").addClass("active").get(0).play();
            $(".js-stage-video-mobile").addClass("active").get(0).play();
        }, 4200);
    });    
    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navMobile
    // *
    // *
    $(".js-navbutton").click(function() {
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
    });

    $(".js-nav-mobile a, .js-header a").click(function() {
        $(".js-nav-mobile").removeClass("active");
        $(".js-navbutton").removeClass("active")
    })
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * chatbot
    // *
    // *
    $(".js-chatbot-button").click(function() {
        $(this).parents(".js-chatbot").toggleClass("active");
    });

    $(window).scroll(function() {
        $(".js-chatbot").removeClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * team
    // *
    // *
    var $team = $(".js-team");

    if ($team.length) {
        $team.each(function () {
            $(this).not('.slick-initialized').slick({
                fade: false,
                dots: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                speed: 1000,
                prevArrow: $(this).parents(".js-paging-wrapper").find(".js-paging-prev"),
                nextArrow: $(this).parents(".js-paging-wrapper").find(".js-paging-next"),
                slidesToShow: 3,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ]
            });
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cards
    // *
    // *
    // map content cards
    if ($(".js-card").length) {

        var $cards = $(".js-card").map(function() {
            return $(this).html();
        }).get();
    
        // map placeholder cards
        var $cardPlaceholders = $(".js-card-placeholder").map(function() {
            return $(this).html();
        }).get();
        
        // make position array
        var $positions = [0, 1, 2, 3, 4, 5, 6, 7];
    
        function updateCards() {
            // take random element in $cards and splice if not in placeholder array
            do {
                var index = Math.floor(Math.random() * $cards.length),
                element = $cards[index];
            } while ($cardPlaceholders.includes(element));        
            $cards.splice(index, 1);     
          
            // Select a random position in cardPlaceholders
            var positionIndex = Math.floor(Math.random() * $positions.length),
                position = $positions[positionIndex];
            $positions.splice(positionIndex, 1);
    
            // replace item in array
            $cardPlaceholders.splice(position, 1, element);
                             
            // Select card in placeholder and replace
            var $selectedCard = $(`.js-card-placeholder:nth-child(${position + 1})`);
            $selectedCard.find(".card").remove();
            $selectedCard.append(element);
    
            // show selected card with transition
            setTimeout(function() { 
                $selectedCard.find(".card").addClass("show");
            }, 1);
                
            // If $cards are empty, reset it
            if ($cards.length === 0) {
                $cards = $(".js-card").map(function() {
                    return $(this).html();
                }).get();
            }
          
            // // If $positions are empty, reset it
            if ($positions.length === 0) {
                $positions = [0, 1, 2, 3, 4, 5, 6, 7];
            }
        }
    
        // start interval; first 8 iterations are faster 
        var counter = 0;
        var intervalId = setInterval(function() {
            updateCards();
            counter++;
            if (counter >= 8) {
                clearInterval(intervalId);
                intervalId = setInterval(function() {
                    updateCards();
                }, (Math.random() * (4000 - 2000)) + 2000);
            }
        }, (Math.random() * (600 - 300)) + 300);
    };


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * typewriter
    // *
    // *
    var typeWriter = $(".js-typewriter"),
        typeLetters = typeWriter.find("span");
        typeSpeed = typeWriter.data("speed");
        typeOffset = typeWriter.data("offset");

    function typeName(element) {
        element.each(function(i) {
            setTimeout(function() {
                $(element[i]).addClass('show');
            }, typeSpeed * (i + 1));
        });
    }

    setTimeout(function() {
        typeName(typeLetters);
    }, typeOffset)

    




$(window).on("load scroll", function(){

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky Header
    // *
    // *
    if($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    }else {
        $(".js-header").removeClass("sticky");
    }
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
    // *
    var $sections = $(".js-nav-section"),
        $nav = $(".js-nav");

    var cur_pos = $(this).scrollTop() + 120;

    $sections.each(function() {
        var top = $(this).offset().top, 
            bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
            $nav.find('li').removeClass('active');
            $sections.removeClass('active');
            $(this).addClass('active');
            $nav.find('a[href="/#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
    });
  
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * logoWall
    // *
    // *
    var $logoWall = $(".js-logowall");

    if ($logoWall.length) {
        $logoWall.each(function () {
            $(this).not('.slick-initialized').slick({
                fade: false,
                dots: false,
                infinite: true,
                arrows: false,
                // prevArrow: $(this).parents(".js-paging-wrapper").find(".js-paging-prev"),
                // nextArrow: $(this).parents(".js-paging-wrapper").find(".js-paging-next"),
                autoplay: true,
                autoPlaySpeed: 4000,
                speed: 1000,
                slidesToShow: 5,
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ]
            });
        })
    }
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliding underline in nav
    // *
    // *
    var $navSlider = $(".js-nav-slider");

    $nav.mouseover(function(event) {
        var target = $(event.target),
            targetWidth = target.outerWidth(),
            targetOffset = target.position().left;

        if (target.is("a")) {
            $navSlider.css("width", targetWidth + "px");
            $navSlider.css("transform", "translateX(" + targetOffset + "px)");
        }
    })

    function navActive() {
        var $activeLink = $nav.find("li.active a");

        if ($activeLink.length > 0) {
            var targetWidthLink = $activeLink.outerWidth(),
                targetOffsetLink = $activeLink.position().left;

            if ($activeLink.parent().index() == 0) {
                $navSlider.addClass("hide");
            } else {
                $navSlider.removeClass("hide");
            }
            $navSlider.css("width", targetWidthLink + "px");
            $navSlider.css("transform", "translateX(" + targetOffsetLink + "px)");
        }
    } 

    // when mouseleave get back to active element
    $nav.mouseleave(function() {
        navActive();
    });

    // fire when loading site and scrolling
    navActive();

    // hide nav-slider when clicken anchor link
    $nav.find("a").click(function(){
        $navSlider.addClass("hide");
        setTimeout(function() { 
            $navSlider.removeClass("hide");
        }, 1200);
    })





    

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parallax
    // *
    // *
    $("[data-parallax-speed]").each(function(){
        var scrollDistance = $(window).scrollTop(),
            elementParallaxOffset = parseInt($(this).css('transform').split(',')[5] ?? 0),
            elementDistance = Math.round($(this).offset().top - elementParallaxOffset),
            windowHeight = $(window).height(),
            parallaxValue = Math.round(((scrollDistance - elementDistance) + (windowHeight / 2))),
            parallaxValue = (parallaxValue * -1),
            parallaxSpeed = $(this).data("parallax-speed"),
            parallaxValue = parallaxValue * parallaxSpeed;

        $(this).css("transform", 'translateX(' + parallaxValue + 'px)');
        $(this).data("parallax-original", parallaxValue);
    });
});

// always start on top
window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}
